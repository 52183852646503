import styled from '@emotion/styled';
import { css } from '@styled-system/css';
import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';

import { Box, Button, Flex, Icon, Name, Text, Tooltip } from '~/components/blocks';
import { LinkPatientButton } from '~/components/partials';
import {
  DirectVisitorDetailFragment,
  DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment,
  OutpatientQuestionnaireEntryAddressFragment,
  WebVisitorDetailFragment,
} from '~/graphql';
import { Label } from '~/utils/label';

import { ContactList } from './ContactList';
import { ProfileDetail } from './ProfileDetail';
import { ProfileSkeleton } from './ProfileSkeleton';

type Props = {
  loading: boolean;
  visitor?:
    | WebVisitorDetailFragment
    | DirectVisitorDetailFragment
    | DirectVisitorOfOutpatientQuestionnaireEntryDetailFragment
    | null;
  partner?: boolean;
  address?: OutpatientQuestionnaireEntryAddressFragment;
};

const Contact = styled('div')(({ theme }) =>
  css({
    backgroundColor: theme.colors.background.bg,
    padding: `${theme.space.m} ${theme.space.l}`,
    marginBottom: theme.space.l,
  }),
);

export const VisitorProfile = React.memo((props: Props) => {
  const { loading, visitor, partner, address } = props;

  const [isExpanded, setExpanded] = useState(false);
  const { getToggleProps, getCollapseProps } = useCollapse({ duration: 100, isExpanded });

  return (
    <Box>
      {loading || !visitor ? (
        <ProfileSkeleton />
      ) : (
        <>
          <Flex alignItems="center">
            <Name
              size="l"
              familyName={visitor.familyName}
              givenName={visitor.givenName}
              phoneticFamilyName={visitor.phoneticFamilyName}
              phoneticGivenName={visitor.phoneticGivenName}
            />
          </Flex>
          <Box>
            <ProfileDetail>
              <ProfileDetail.Item>{Label.age(visitor.birthDate)}</ProfileDetail.Item>
              <ProfileDetail.Item>（{Label.sex(visitor.sex)}）</ProfileDetail.Item>
              <ProfileDetail.Item>
                <Flex>
                  {!partner && (
                    <Tooltip
                      content={
                        <>
                          連携できるのは、CLINICS
                          <br />
                          アプリ経由の予約のみです
                        </>
                      }
                    >
                      <div>
                        <LinkPatientButton disabled />
                      </div>
                    </Tooltip>
                  )}
                  <Button
                    size="ms"
                    {...getToggleProps({
                      onClick: () => setExpanded((_prev) => !_prev),
                    })}
                  >
                    <Icon size="s" icon="people" />
                    {isExpanded ? '閉じる' : '詳細'}
                  </Button>
                </Flex>
              </ProfileDetail.Item>
            </ProfileDetail>
          </Box>
          <div {...getCollapseProps()}>
            <Contact>
              <ContactList>
                <ContactList.Item>
                  <Icon size="m" icon="tel" />
                  {visitor.mobilePhone}
                </ContactList.Item>
                {address && (
                  <ContactList.Item>
                    <Icon size="m" icon="address" />
                    <Text>
                      {address.postalCode}
                      <br />
                      {`${address.prefecture}${address.city}${address.line}`}
                    </Text>
                  </ContactList.Item>
                )}
                <ContactList.Item>
                  <Icon size="m" icon="people" />
                  {Label.warekiBirthDate(visitor.birthDate)}
                </ContactList.Item>
              </ContactList>
            </Contact>
          </div>
        </>
      )}
    </Box>
  );
});

VisitorProfile.displayName = 'VisitorProfile';
