import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Box, Flex, Icon, Loader, Modal, Text } from '~/components/blocks';
import { ExternalLink } from '~/components/blocks/ExternalLink';
import { WebVisitorChargePanel } from '~/components/partials/WebVisitorChargePanel';
import { webVisitorChargeModalState } from '~/state/reception/atoms';

import { useFetchAppointment } from './use-fetch-appointment';

export const WebVisitorChargeModal = () => {
  const state = useRecoilValue(webVisitorChargeModalState);
  const resetState = useResetRecoilState(webVisitorChargeModalState);

  const appointment = useFetchAppointment();

  useEffect(() => {
    if (!state.isOpen) {
      resetState();
    }
  }, [resetState, state.isOpen]);

  return (
    <Modal open={state.isOpen} size="s" onClose={resetState}>
      <Modal.Header>
        <Flex alignItems="center">
          <Text>会計</Text>
          <Flex alignItems="center" justifyContent="flex-end" width="83%">
            <Icon icon="hint" size="l" color="grey" />
            <ExternalLink
              color="black"
              url="https://intercom.help/pharms/ja/articles/10115094-%E4%BC%9A%E8%A8%88%E3%81%AB%E9%96%A2%E3%81%99%E3%82%8B%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E3%81%94%E8%B3%AA%E5%95%8F"
              fontSize="s"
            >
              会計で困ったときは
            </ExternalLink>
          </Flex>
        </Flex>
      </Modal.Header>
      {appointment ? (
        <WebVisitorChargePanel appointment={appointment} />
      ) : (
        <Modal.Body>
          <Box position="relative" height="240px">
            <Loader open inside />
          </Box>
        </Modal.Body>
      )}
    </Modal>
  );
};
